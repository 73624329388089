@import 'borders';
@import 'bp';
@import 'fonts';
@import 'palette';
@import 'spacing';

$passwordless-container-width: 488px;
$confirmation-message-width: 340px;
$modal-width: 468px;

.input {
    margin-bottom: $spacing-6;
}

.page-container {
    align-items: center;
    display: flex;
    height: calc(100vh - 30px);
    justify-content: center;
    position: relative;
}

.resend {
    font-family: $ff-interstate-light;
    font-size: $fs-1;
}

.resend-link {
    background: none;
    border: none;
    color: $brand-color-light-1;
    cursor: pointer;
    font-size: $fs-1;
    font-weight: 700;
    margin: auto;
    text-decoration: underline;
    width: fit-content;
}

.container {
    text-align: center;
    width: $passwordless-container-width;

    @media only screen and (max-width: $bp-max-mobile) {
        padding: $spacing-00;
    }
}

.message {
    font-family: $ff-interstate-light;
    font-size: $fs-2;
    margin: $spacing-6 auto;
    max-width: $confirmation-message-width;
    text-align: center;
}

.subtitle {
    font-family: $ff-interstate-light;
    font-size: $fs-2;
    font-weight: 300;
    margin: auto;
    max-width: $confirmation-message-width;
    text-align: center;
}

.title {
    color: $dark-color;
    font-family: $ff-interstate-bold;
    font-size: $fs-05;
    font-weight: 700;
    height: $spacing-5;
    letter-spacing: -0.0135em;
    line-height: 114%;
    margin: $spacing-2 0 $spacing-6 0;
    text-align: center;
}

.divide {
    align-items: center;
    color: $neutral-color-3;
    display: flex;
    font-size: $fs--1;
    font-weight: 700;
    line-height: $spacing-07;
}

.line {
    border: $bw-0 solid $neutral-color-7;
    flex-grow: 1;
    margin: 0 $spacing-00;
}

.button {
    height: $spacing-07;
    margin-bottom: $spacing-03;
    text-transform: uppercase;

    &:disabled {
        background-color: $neutral-color-13;
    }
}

.apple-button {
    height: $spacing-07;
    margin: $spacing-03 0;
    text-align: -webkit-center;

    &:disabled {
        background-color: $neutral-color-13;
        cursor: progress;
    }
}

.apple-icon {
    height: $spacing-2;
    margin-right: $spacing-00;
    width: $spacing-2;
}

.account-label {
    color: $neutral-color-13;
    font-size: $fs-2;
    margin: $spacing-00 0;
}

.email label > input[type='email'] {
    margin: $spacing--1 0 $spacing-3;

    &:focus {
        border: $bw-1 solid $brand-color-light-1;

        + span {
            color: $brand-color-light-1;
        }
    }
}

.modal {
    > div {
        width: $modal-width;

        h3 {
            font-family: $ff-interstate-bold;
            font-size: $fs-4;
            text-transform: none;
        }
    }

    header {
        color: $dark-color;
    }

    p {
        color: $neutral-color-6;
        font-family: $ff-interstate-regular;
        font-size: $fs-1;
    }
}
